import React from "react";
import {AppSkinProps} from "../../../types/decisions";
import CriteriaSetting from "./CriteriaSetting";

interface CriteriaSettingsProps {
    criteriaData: AppSkinProps['criteriaData'];
    fieldInitialValues: AppSkinProps['fieldInitialValues'];
    onTradeoffValueChange: AppSkinProps['onTradeoffValueChange'];
    onSliderChange: AppSkinProps['onSliderChange'];
    handleClose?: () => void;
}

const CriteriaSettings = ({
    criteriaData,
    fieldInitialValues,
    onTradeoffValueChange,
    onSliderChange,
    handleClose,
}: CriteriaSettingsProps) => {
    return (
        <div>
            {criteriaData.fields.map((field, index) => {
                if (field.hide_field === '1') {
                    return null;
                }

                return (
                    <CriteriaSetting
                        key={field.id}
                        field={field}
                        index={index}
                        fieldInitialValues={fieldInitialValues}
                        onTradeoffValueChange={onTradeoffValueChange}
                        onSliderChange={onSliderChange}
                        handleModalClose={handleClose}
                    />
                )
            })}
        </div>
    )
}

export default CriteriaSettings;