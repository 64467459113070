import React, {ChangeEvent, EventHandler} from "react";
import './ColorPicker.scss';

const ColorPicker = ({
    value,
    onChange,
    onChangeCommited,
}: {
    value: string;
    onChange: EventHandler<ChangeEvent<HTMLInputElement>>;
    onChangeCommited?: EventHandler<ChangeEvent<HTMLInputElement>>;
}) => {

    return (
        <div className="my-color-picker">
            <label className="color-selector">
                <span className="circle" style={{background: value}}/>
                <span>{value}</span>
                <input
                    type="color"
                    value={value}
                    onChange={onChange}
                    onBlur={onChangeCommited}
                    className="hidden"
                />
            </label>
        </div>
    )
};

export default ColorPicker;