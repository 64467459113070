import React, {useEffect, useRef, useState} from 'react';
import {Button, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {animated} from 'react-spring'
import {AppCriteriaField} from "../../types/decisions";
import Image from "../image/Image";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import './draggable.scss'

interface CriteriaItem extends AppCriteriaField {
    backgroundColor: string;
    angle: number;
    translateY: number;
    translateYOffset: number;
    name: string;
    flipped: boolean;
}

interface Props {
    initialCriteriaFields: AppCriteriaField[];
    onCriteriaApply: () => void;
    onTradeoffValueChange: (newValue: number, idx: number) => void,
    onSliderChange: (newValue: number, idx: number) => void,
    onCriteriaSettingsOpen: (index: number) => void,
}

const colors = [
    '#2E8B57',
    '#1E90FF',
    '#FF6347',
    '#4B0082',
    '#FF4500',
    '#FFB6C1',
    '#3498db',
    '#6B8E23',
    '#800080',
    '#34495E',
];

const angles = [-15, -10, 0, 10, 15];
const offsets = [48, 13, 0, 13, 52];
const CARDS_PER_PAGE = 5;

const generateCriteriaInitialInfo = (initialCriteriaFields: AppCriteriaField[]): CriteriaItem[] => {
    return initialCriteriaFields.filter(item => item.hide_importance !== '1').map((item, index) => {
        return {
            ...item,
            backgroundColor: colors[index],
            angle: angles[index % CARDS_PER_PAGE],
            name: typeof item.name === 'object' ? item.name.value : item.name,
            translateYOffset: offsets[index % CARDS_PER_PAGE],
            translateY: -item.importance,
            flipped: false,
        }
    });
};

const DraggableDiv = ({
    initialCriteriaFields,
    onSliderChange,
    onCriteriaApply,
    onCriteriaSettingsOpen,
}: Props) => {
    const [dragging, setDragging] = useState(false);
    const selectedCardIdRef = useRef<string | null>(null);
    const maxDragUp = -100;
    const [criteria, setCriteria] = useState<CriteriaItem[]>([]);
    const [criteriaFirstIndex, setCriteriaFirstIndex] = useState(0);

    useEffect(() => {
        setCriteria(generateCriteriaInitialInfo(initialCriteriaFields));
    }, [initialCriteriaFields])

    const handleDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!dragging) return;

        if (selectedCardIdRef === null) {
            return;
        }

        const dragAmount = Math.min(0, event.clientY - window.innerHeight + 400);

        if (dragAmount >= maxDragUp) {
            setCriteria((criteria) => {
                return criteria.map((item) => {
                    if (item.id === selectedCardIdRef.current) {
                        return {
                            ...item,
                            importance: Math.abs(dragAmount),
                            translateY: dragAmount,
                        };
                    }

                    return item;
                })
            })
        }
    };

    const handleRelease = () => {
        setDragging(false);
        const currentIndex = criteria.findIndex(item => item.id === selectedCardIdRef.current);
        if (-1 !== currentIndex) {
            onSliderChange(+criteria[currentIndex].importance, currentIndex)
        }
        selectedCardIdRef.current = null;
    };

    const onOpenSettings = (id: string) => {
        const index = criteria.findIndex(item => item.id === id);
        if (index !== -1) {
            onCriteriaSettingsOpen(index);
        }
    }

    return (
        <>
            <div
                onMouseDown={() => setDragging(true)}
                onMouseMove={handleDrag}
                onMouseUp={handleRelease}
                onMouseLeave={handleRelease}
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    perspective: 1000,
                    padding: -maxDragUp,
                }}
            >
                <div className="arc arc_start">
                    <div className='thumb-container'>
                        <ThumbUpIcon/>
                    </div>
                </div>
                <div className="arc2 arc_start">
                    <div className='thumb-container'>
                        <ThumbUpIcon/>
                        <ThumbUpIcon/>
                    </div>
                </div>
                <div className="arc3 arc_start">
                    <div className='thumb-container'>
                        <ThumbUpIcon/>
                        <ThumbUpIcon/>
                        <ThumbUpIcon/>
                    </div>
                </div>

                {criteria.slice(criteriaFirstIndex, criteriaFirstIndex + CARDS_PER_PAGE)
                .map((criteriaItem, index) => {
                    const isSelected = criteriaItem.id === selectedCardIdRef.current;
                    const transformRotate = `rotate(${angles[index]}deg)`;
                    const transformY = `translateY(${+criteriaItem.translateY + offsets[index]}px)`;
                    const transformValues = `${transformRotate} ${transformY}`

                    return (
                        <animated.div
                            key={criteriaItem.id}
                            onMouseDown={() => selectedCardIdRef.current = criteriaItem.id}
                            className='app-criteria-card-item'
                            style={{
                                outline: isSelected ? '2px solid black' : '1px solid grey',
                                cursor: isSelected ? 'grab' : 'default',
                                transform: transformValues,
                                // backgroundColor: criteriaItem.backgroundColor,
                                backgroundColor: 'white',
                                zIndex: isSelected ? 2 : 1,
                            }}
                        >
                            <div className='importance-bar' style={{width: criteriaItem.importance + '%'}}/>
                            <h3 className='importance-card-title'>
                                {criteriaItem.name}
                                <IconButton style={{marginLeft: '.5rem'}}
                                            onClick={() => onOpenSettings(criteriaItem.id)}>
                                    <EditIcon color='primary' fontSize='small'/>
                                </IconButton>
                            </h3>
                            <Image src={criteriaItem.icon} className='app-criteria-icon'/>
                        </animated.div>
                    );
                })}
            </div>
            <div className='draggable-div-actions'>
                <Button
                    variant='outlined'
                    disabled={criteriaFirstIndex === 0}
                    onClick={() => setCriteriaFirstIndex(p => p - 1)}
                >
                    <ChevronLeftIcon/>
                </Button>
                <Button
                    variant='contained'
                    className='decide-btn'
                    onClick={onCriteriaApply}
                >
                    Decide
                </Button>
                <Button
                    variant='outlined'
                    disabled={criteriaFirstIndex === criteria.length - CARDS_PER_PAGE}
                    onClick={() => setCriteriaFirstIndex(p => p + 1)}
                >
                    <ChevronRightIcon/>
                </Button>
            </div>
        </>
    );
};

export default DraggableDiv;