import React from 'react';
import {AppCriteriaField, AppSkinProps} from "../../../types/decisions";
import {getFieldName} from "../../../utils";
import {
    AppCriteriaTypeDate,
    AppCriteriaTypeNumber,
    AppCriteriaTypeSelect,
    AppCriteriaTypeText,
    AppCriteriaTypeZip,
    AppCriteriaTypeColor,
    transformCriteriaObjectToArray
} from "./TrandeoffCriteriaFields";
import {Button} from "@mui/material";

interface Props {
    onSliderChange: AppSkinProps['onSliderChange'];
    onTradeoffValueChange: AppSkinProps['onTradeoffValueChange'];
    fieldInitialValues: AppSkinProps['fieldInitialValues'];
    field: AppCriteriaField,
    index: number;
    areCTAsDisplayed?: boolean;
    handleModalClose?: () => void;
}

const CriteriaSetting = ({
    field,
    index,
    onSliderChange,
    onTradeoffValueChange,
    fieldInitialValues,
    areCTAsDisplayed = false,
    handleModalClose,
}: Props) => {
    let content = <></>;

    if (field.type === 'text' || field.type === 'dynamic') {
        content = (
            <div key={field.id} className='results-app-settings-item'>
                <div className='left'>
                    <div className='title'>{getFieldName(field.name)}</div>
                </div>
                <div className='right'>
                    <AppCriteriaTypeText
                        value={field.val as string}
                        hideImportance={true}
                        importance={+field.importance}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        criteriaConstraints={{}}
                        setCriteriaConstraints={() => {
                        }}
                        id={field.id}
                    />
                </div>
            </div>
        );
    } else if (field.type === 'number') {
        content = (
            <div key={field.id} className='results-app-settings-item'>
                <div className='left'>
                    <div className='title'>{getFieldName(field.name)}</div>
                </div>
                <div className='right'>
                    <AppCriteriaTypeNumber
                        value={+field.val}
                        importance={+field.importance}
                        min={+field.min}
                        max={+field.max}
                        hideImportance={true}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        criteriaConstraints={{}}
                        setCriteriaConstraints={() => {
                        }}
                        id={field.id}
                    />
                </div>
            </div>
        );
    } else if (
        field.type === 'multiselect'
        || field.type === 'select'
        || field.type === 'bool'
    ) {
        content = (
            <div key={field.id} className='results-app-settings-item'>
                <div className='left'>
                    <div className='title'>{getFieldName(field.name)}</div>
                </div>
                <div className='right'>
                    <AppCriteriaTypeSelect
                        items={transformCriteriaObjectToArray(field.val)}
                        importance={+field.importance}
                        hideImportance={true}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        initialValues={fieldInitialValues[index].val as { [key: string]: string }}
                        idx={index}
                        criteriaConstraints={{}}
                        setCriteriaConstraints={() => {
                        }}
                        id={field.id}
                    />
                </div>
            </div>
        )
    } else if (field.type === 'zipcode') {
        content = (
            <div key={field.id} className='results-app-settings-item'>
                <div className='left'>
                    <div className='title'>{getFieldName(field.name)}</div>
                </div>
                <div className='right'>
                    <AppCriteriaTypeZip
                        importance={+field.importance}
                        hideImportance={true}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                    />
                </div>
            </div>
        )
    } else if (field.type === 'date') {
        content = (
            <div key={field.id} className='results-app-settings-item'>
                <div className='left'>
                    <div className='title'>{getFieldName(field.name)}</div>
                </div>
                <div className='right'>
                    <AppCriteriaTypeDate
                        importance={+field.importance}
                        hideImportance={true}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        value={field.val as string}
                    />
                </div>
            </div>
        );
    } else if (field.type === 'color') {
        content = (
            <div key={field.id} className='results-app-settings-item'>
                <div className='left'>
                    <div className='title'>{getFieldName(field.name)}</div>
                </div>
                <div className='right'>
                    <AppCriteriaTypeColor
                        importance={+field.importance}
                        hideImportance={true}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        value={field.val as string}
                    />
                </div>
            </div>
        );
    }

    const onCancel = () => {
        handleModalClose?.()
    };


    const onSave = () => {
        handleModalClose?.()
    };

    return (
        <div className='custom-modal-content'>
            {content}
            {areCTAsDisplayed ? (
                <div className='custom-modal-content-ctas' style={{textAlign: 'right'}}>
                    <Button variant='outlined' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={onSave} style={{marginLeft: 16}}>
                        Save
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default CriteriaSetting;