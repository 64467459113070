import React from 'react';
import {AppSkinProps} from "../../../types/decisions";
import AppResults from "../app-components/AppResults/AppResults";
import CriteriaImportanceAndSettings from "../app-components/CriteriaImportanceAndSettings";

const AppSkin3 = ({
    results,
    onRerank,
    criteriaLoading,
    Loading,
    criteriaData,
    onSliderChange,
    onResetTradeOffs,
    onCriteriaApply,
    resultLoading,
    pageInfo,
    onPageChange,
    onTradeoffValueChange,
    fieldInitialValues,
}: AppSkinProps) => {
    return (
        <div className='application'>
            {criteriaLoading ? (
                <Loading/>
            ) : (
                <>
                    <div className='application-data'>
                        <img src={criteriaData.icon} alt={criteriaData.name}/>
                        <div className='app-info'>
                            <h1 className='app-name'>{criteriaData.name}</h1>
                            <div className='app-description'>{criteriaData.description}</div>
                        </div>
                    </div>
                    <div className='application-details'>
                        <div className='app-criteria' id='app-criteria'>
                            {criteriaLoading ? (
                                <Loading/>
                            ) : (
                                <CriteriaImportanceAndSettings
                                    criteriaData={criteriaData}
                                    onSliderChange={onSliderChange}
                                    fieldInitialValues={fieldInitialValues}
                                    onTradeoffValueChange={onTradeoffValueChange}
                                    onResetTradeOffs={onResetTradeOffs}
                                    onCriteriaApply={onCriteriaApply}
                                />
                            )}
                        </div>
                        <div className='app-results'>
                            {resultLoading ? (
                                <Loading/>
                            ) : (
                                <AppResults
                                    results={results}
                                    pageInfo={pageInfo}
                                    onPageChange={onPageChange}
                                    onRerank={onRerank}
                                    template='largeProducts'
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
};

export default AppSkin3;