import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import type {SliderProps} from "@mui/material/Slider/Slider";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

type SliderCustomProps = SliderProps & {
    hideThumbButtons?: boolean;
    leftText?: string;
    rightText?: string;
    isEditable?: boolean;
    isEditingDisabled?: boolean;
    onEditIconClick?: () => void;
    value: number;
}

export default function TriangleSlider({
  hideThumbButtons = false,
  leftText = '',
  rightText = '',
  isEditable = false,
  isEditingDisabled = false,
  onEditIconClick,
  value,
  ...props
}: SliderCustomProps) {
  const showHelpTexts = leftText?.trim() && rightText?.trim();

    return (
      <>
        <Box display="flex" alignItems="center">
          {!hideThumbButtons ?
            <ThumbDownIcon fontSize='small' style={{marginRight: '1rem', marginBottom: -5}} color='primary'/> : null}
          <Slider
            defaultValue={50}
            aria-label="Triangle"
            valueLabelDisplay="auto"
            value={value}
            {...props}
            sx={{
              "& .MuiSlider-rail": {
                height: "20px",
                borderRadius: 0,
                clipPath: "polygon(0% 75%,100% 0%,100% 100%,0% 100%)",
                background: `linear-gradient(90deg, #1976d2 ${value}%, #1976d2 ${value}%, #1976d2 ${value}%, #eee ${value}%)`,
                opacity: 1
              },
              "& .MuiSlider-track": {
                display: "none"
              },
              "& .MuiSlider-thumb": {
                top: "70%",
                backgroundColor: "#1976d2",
                border: "4px solid #fff",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.1)",
                "&:before": {
                  boxShadow: "none"
                }
              },
              "& [data-index='0']:not(.MuiSlider-markLabel)": {
                top: `${70 - value / 5}%`,
                width: `calc(20px + ${0.15 * value}px)`,
                height: `calc(20px + ${0.15 * value}px)`
              },
              "& .MuiSlider-markLabel": {
                marginTop: "0.75rem",
                color: "#c0c0c0",
                "&.MuiSlider-markLabelActive": {
                  color: "#1976d2"
                }
              },
              "& .MuiSlider-mark": {
                display: "none"
              }
            }}
          />
          {!hideThumbButtons ?
            <ThumbUpIcon fontSize='small' style={{marginLeft: '1.25rem'}} color='primary'/> : null}
          {isEditable ? (
            <IconButton style={{marginLeft: '.5rem'}} onClick={onEditIconClick} disabled={isEditingDisabled}>
              <EditIcon color={isEditingDisabled ? 'disabled' : 'primary'} fontSize='small'/>
            </IconButton>
          ) : null}
        </Box>
        {showHelpTexts ? (
          <div
            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 'small'}}>
            <span>{leftText}</span>
            <span>{rightText}</span>
          </div>
        ) : null}
      </>
    );
}
