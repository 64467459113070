import React, {ReactElement} from 'react';
import {Button, Pagination} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {AppCriteriaField, AppResultInfo, AppSkinProps, ResultTemplates} from '../../../../types/decisions';
import {getFieldName} from '../../../../utils';
import './AppResults.scss';

interface AppResultsProps {
    results: AppSkinProps['results'];
    onRerank: AppSkinProps['onRerank'];
    currentPage: number;
}

const ResultProductCardSpecs = ({id, nonTechnicalFields}: {
    id: AppResultInfo['id'], nonTechnicalFields: AppResultInfo['nonTechnicalFields']
}) => {
    const renderSpecByType = (value: AppCriteriaField['val'], type: AppCriteriaField['type']): ReactElement => {
        if (value === null) {
            return <div>-</div>;
        }

        switch (type) {
            case 'select':
            case 'multiselect':
            case 'bool':
                return (
                    <div>
                        {typeof value === 'object' ?
                            Object.keys(value).map((key) => {
                                // multiselect not checked case
                                if (value[key] === '-1') {
                                    return null;
                                }

                                return (
                                    <div className='multi-spec-item' key={key + value[key]}>
                                        {key} ({value[key]})
                                    </div>
                                )
                            }) : (
                                value
                            )
                        }
                    </div>
                );
            case 'color':
                return (
                    <div>
                        {typeof value === 'object' ? (
                            <div className='new-results-product-specs-spec-colors'>
                                {Object.keys(value).map((key) => (
                                    <div
                                        key={key + value[key]}
                                        className='new-results-product-specs-spec-color'
                                        style={{backgroundColor: value[key]}}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div
                                className='new-results-product-specs-spec-color'
                                style={{backgroundColor: value}}
                            />
                        )
                        }
                    </div>
                );
            case 'number':
            case 'text':
            case 'dynamic':
            case 'date':
            case 'zipcode':
                return <div>{value as string}</div>;
            default:
                return <div>-</div>;
        }
    };

    return (
        <div className='new-results-product-specs'>
            {nonTechnicalFields.map(({id: itemId, value, name, type}) => {
                return (
                    <div
                        className='new-results-product-specs-spec'
                        key={id + itemId}
                    >
                        <div>{getFieldName(name)}</div>
                        {renderSpecByType(value, type)}
                    </div>
                );
            })}
        </div>
    );
}

const ProductResults = ({results, onRerank, currentPage}: {
    results: AppResultInfo[];
    onRerank: (rerankProductId: string, scrollToTop?: boolean) => void;
    currentPage: number;
}): React.JSX.Element => {
    return <>
        {results.map((result, index) => {
            const name = getFieldName(result.data.name);
            const productLink = result.data.productlink?.value;
            const actionLink = result.data.actionlink?.value;
            const actionCTAName = result.data.actionbuttontext?.value;
            let shouldDisplayInferButton = true;
            if (index === 0 && currentPage === 1) {
                shouldDisplayInferButton = false;
            }

            return (
                <div className='new-results-item' key={result.id}>
                    <div className='image-container' style={{backgroundImage: `url('${result.icon}')`}}/>
                    <div className='new-results-item-details'>
                        <div className='new-results-item-details-rank'>
                            <div className='new-results-item-details-rank-bar' style={{width: result.rank + '%'}}/>
                            <div className='new-results-item-details-rank-rate'>
                                {result.rank === '-' ? <>&nbsp;</> : result.rank + '%'}
                            </div>
                        </div>
                        <div>
                            <h3
                                className={`truncate ${productLink ? 'product-link' : ''}`}
                                title={productLink || name}
                                onClick={() => productLink && window.open(productLink, '_blank')}
                            >
                                {name}
                            </h3>
                            <ResultProductCardSpecs id={result.id} nonTechnicalFields={result.nonTechnicalFields}/>
                        </div>
                        <div className='new-results-item-details-ctas'>
                            {shouldDisplayInferButton && (
                                <Button
                                    className='new-results-item-details-ctas-cta'
                                    variant='outlined'
                                    onClick={() => onRerank(result.id, true)}
                                    startIcon={<ArrowUpwardIcon/>}
                                />
                            )}
                            {actionLink && actionCTAName && (
                                <Button
                                    className='new-results-item-details-ctas-cta action-link'
                                    variant='outlined'
                                    onClick={() => window.open(actionLink, '_blank')}
                                >
                                    {actionCTAName}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )
        })}
    </>;
}

const ThreeColumns = ({results, onRerank, currentPage}: AppResultsProps) => {
    return (
        <div className='threeColumns'>
            <ProductResults
                results={results}
                onRerank={onRerank}
                currentPage={currentPage}
            />
        </div>
    );
};

const LargeProductCards = ({results, onRerank, currentPage}: AppResultsProps) => {
    return (
        <div className='largeProducts'>
            <ProductResults
                results={results}
                onRerank={onRerank}
                currentPage={currentPage}
            />
        </div>
    )
}

const AppResults = ({
    pageInfo,
    results,
    onPageChange,
    onRerank,
    template = '3columns',
}: Omit<AppResultsProps, 'currentPage'> & {
    template?: ResultTemplates;
    pageInfo: AppSkinProps['pageInfo'];
    onPageChange: AppSkinProps['onPageChange'];
}) => {
    if (results.length === 0) {
        return (
            <div className='no-results'>
                No products found.
            </div>
        );
    }

    let ResultTemplate = ThreeColumns;

    if (template === 'largeProducts') {
        ResultTemplate = LargeProductCards;
    }

    return (
        <div className='new-app-results-container'>
            <h3>Results ({pageInfo.total})</h3>
            <div className='new-app-results'>
                <ResultTemplate results={results} onRerank={onRerank} currentPage={pageInfo.current_page}/>
            </div>
            <div className='app-results-page-info'>
                {/*Displayed products: <b>{pageInfo.start}</b> to <b>{pageInfo.end}</b> of <b>{pageInfo.total}</b>.*/}
                <Pagination
                    page={pageInfo.current_page}
                    count={pageInfo.total_pages}
                    shape='rounded'
                    onChange={(_, page) => onPageChange(page)}
                />
            </div>
        </div>
    );
};

export default AppResults;