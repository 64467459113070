import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "./components/header";
import Footer from "./components/footer";
import Applications from "./components/applications";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import VirtualAgent from "./components/VirtualAgent";
import Logout from "./pages/logout/Logout";
import Application from "./components/application";
import './app.scss';

function App() {
    const [tmpConfigs, setTmpConfigs] = useState({
        show: {
            progressbar: false,
            header: true,
            footer: true,
            menu: false,
            menuitems: {
                importance: {
                    show: true
                },
                settings: {
                    show: true
                },
                reset: {
                    show: true
                },
                decide: {
                    show: true
                }
            }
        },
        popup: {
            show: false,
            threshold: 30,
        }
    });

    return (
        <div className='app'>
            <Router>
                {tmpConfigs.show.header ? <Header/> : null}
                <Routes>
                    <Route path="/" element={<Applications/>}/>
                    <Route
                        path="/apps/:id"
                        element={
                            <Application tmpConfigs={tmpConfigs} setTmpConfigs={setTmpConfigs}/>
                        }
                    />
                    <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                    <Route path="/virtual-agent/:id" element={<VirtualAgent/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="*" element={<h2 style={{padding: '0 16px'}}>Coming soon...</h2>}/>
                </Routes>
                {tmpConfigs.show.footer ? <Footer/> : null}
            </Router>
        </div>
    );
}

export default App;
