import React from 'react';
import {AppSkinProps} from "../../../types/decisions";
import Modal from "../../modal/Modal";
import DraggableDiv from "../../draggableDiv/DraggableDiv";
import CriteriaSetting from "../app-components/CriteriaSetting";
import AppResults from "../app-components/AppResults/AppResults";

const AppSkin6 = ({
    results,
    onRerank,
    criteriaLoading,
    criteriaData,
    onSliderChange,
    onCriteriaApply,
    resultLoading,
    onPageChange,
    pageInfo,
    Loading,
    onTradeoffValueChange,
    fieldInitialValues,
    tmpConfigs,
}: AppSkinProps) => {
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<React.ReactElement>(<></>);
    const handleClose = () => {
        setModalContent(<></>);
        setOpen(false);
    }

    const onCriteriaSettingsOpen = (idx: number) => {
        setOpen(true);
        setModalContent(
            <CriteriaSetting
                field={criteriaData.fields[idx]}
                index={idx}
                onSliderChange={onSliderChange}
                onTradeoffValueChange={onTradeoffValueChange}
                fieldInitialValues={fieldInitialValues}
                handleModalClose={handleClose}
                areCTAsDisplayed
            />
        );
    };

    return (
        <div className='application skin6'>
            {criteriaLoading ? (
                <Loading/>
            ) : (
                <>
                    <div className='application-data'>
                        <img src={criteriaData.icon} alt={criteriaData.name}/>
                        <div className='app-info'>
                            <h1 className='app-name'>{criteriaData.name}</h1>
                            <div className='app-description'>{criteriaData.description}</div>
                        </div>
                    </div>
                    <div className='application-details'>
                        <div className='app-criteria' id='app-criteria'>
                            {criteriaLoading ? (
                                <Loading/>
                            ) : (
                                <div className='app-criteria-draggable-cards'>
                                    <DraggableDiv
                                        initialCriteriaFields={criteriaData.fields}
                                        onCriteriaApply={onCriteriaApply}
                                        onTradeoffValueChange={onTradeoffValueChange}
                                        onSliderChange={onSliderChange}
                                        onCriteriaSettingsOpen={onCriteriaSettingsOpen}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='app-results skin6-products'>
                            {resultLoading ? (
                                <Loading/>
                            ) : (
                                <AppResults
                                    results={results}
                                    onRerank={onRerank}
                                    onPageChange={onPageChange}
                                    pageInfo={pageInfo}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <Modal isOpen={open} handleClose={handleClose}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default AppSkin6;