import React from "react";
import Draggable from "react-draggable";
import EditIcon from "@mui/icons-material/Edit";

interface LeafProps {
    name: string;
    angle: number;
    radius: number;
    center: { x: number; y: number };
    onDrag: (newRadius: number) => void;
    onOpenSettings: () => void;
}

const Leaf: React.FC<LeafProps> = ({ name, angle, radius, center, onDrag, onOpenSettings }) => {
    const clampedRadius = Math.max(80, Math.min(radius, 200));
    const size = Math.max(80, Math.min(200, clampedRadius / 1.5));
    const fontSize = Math.max(8, Math.min(20, size / 8));
    const x = center.x + clampedRadius * Math.cos(angle) - size / 2;
    const y = center.y + clampedRadius * Math.sin(angle) - size / 2;
    // const percentage = Math.round(((clampedRadius - 80) / 120) * 100);

    const thumbRadius = 280;
    const lineRadius = 265;
    const polygonRadius = 267;

    const iconX = center.x + thumbRadius * Math.cos(angle);
    const iconY = center.y + thumbRadius * Math.sin(angle);

    const lineX = center.x + lineRadius * Math.cos(angle);
    const lineY = center.y + lineRadius * Math.sin(angle);

    const polyX = center.x + polygonRadius * Math.cos(angle);
    const polyY = center.y + polygonRadius * Math.sin(angle);

    const arrowHeadSize = 10; // Size of the arrowhead
    const arrowX1 = polyX - arrowHeadSize * Math.cos(angle - Math.PI / 6);
    const arrowY1 = polyY - arrowHeadSize * Math.sin(angle - Math.PI / 6);
    const arrowX2 = polyX - arrowHeadSize * Math.cos(angle + Math.PI / 6);
    const arrowY2 = polyY - arrowHeadSize * Math.sin(angle + Math.PI / 6);

    const handleDrag = (_: any, data: any) => {
        const dx = data.x + size / 2 - center.x;
        const dy = data.y + size / 2 - center.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const clampedDistance = Math.max(80, Math.min(distance, 200));
        onDrag(clampedDistance);
    };

    return (
        <>
            <svg
                style={{position: "absolute", top: 0, left: 0, pointerEvents: "none", zIndex: 0}}
                width="100%"
                height="100%"
            >
                <line
                    x1={center.x}
                    y1={center.y}
                    // x2={center.x + clampedRadius * Math.cos(angle)}
                    // y2={center.y + clampedRadius * Math.sin(angle)}
                    x2={lineX}
                    y2={lineY}
                    stroke="#807373"
                    strokeWidth="2"
                />
                <polygon
                    points={`${polyX},${polyY} ${arrowX1},${arrowY1} ${arrowX2},${arrowY2}`}
                    fill="#807373"
                />
                <text
                    x={iconX}
                    y={iconY}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontSize="16"
                    fill="#f90"
                    style={{pointerEvents: "none"}}
                >
                    👍
                </text>
            </svg>
            <Draggable
                position={{x, y}}
                onDrag={(e, data) => handleDrag(e, data)}
                axis="none"
            >
                <div
                    style={{
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#7198C8FF",
                        color: "#fff",
                        borderRadius: "50%",
                        border: "1px solid grey",
                        width: size,
                        height: size,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: fontSize,
                        zIndex: 1,
                        userSelect: "none",
                        textAlign: "center",
                        overflow: "hidden",
                        cursor: "grab",
                    }}
                >
                    <div style={{ fontSize: fontSize * 0.8, display: 'flex', alignItems: "center" }}>
                        <span>{name}</span>
                        <EditIcon onClick={onOpenSettings} style={{ fontSize: fontSize * 0.8, fill: '#fff', marginLeft: 4, cursor: "pointer" }}/>
                    </div>
                    {/*<span style={{ fontSize: fontSize * 0.8 }}>{percentage}%</span>*/}
                </div>
            </Draggable>
        </>
    );
};

export default Leaf;
