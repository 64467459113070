import {AppSkinProps} from "../../../types/decisions";
import React from "react";
import BasicTabs from "../../tabs/tabs";
import {getFieldName, scrollToAnchor} from "../../../utils";
import {TriangleSlider} from "../../slider";
import CriteriaSetting from "./CriteriaSetting";
import CriteriaSettings from "./CriteriaSettings";
import {Button} from "@mui/material";
import Modal from "../../modal/Modal";

interface CriteriaImportanceAndSettingsProps {
    criteriaData: AppSkinProps['criteriaData'];
    onSliderChange: AppSkinProps['onSliderChange'];
    fieldInitialValues: AppSkinProps['fieldInitialValues'];
    onTradeoffValueChange: AppSkinProps['onTradeoffValueChange'];
    onResetTradeOffs: AppSkinProps['onResetTradeOffs'];
    onCriteriaApply: AppSkinProps['onCriteriaApply'];
}

const CriteriaImportanceAndSettings = ({
    criteriaData,
    onSliderChange,
    fieldInitialValues,
    onTradeoffValueChange,
    onResetTradeOffs,
    onCriteriaApply,
}: CriteriaImportanceAndSettingsProps): React.JSX.Element => {
    const [isImportanceOpen, setIsImportanceOpen] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<React.ReactElement>(<></>);
    const handleClose = () => {
        setOpen(false);
        setModalContent(<></>);
    }

    return (
        <div>
            <BasicTabs
                tabNames={[isImportanceOpen ? 'Importance' : 'Settings']}
                tabs={[
                    <>
                        {isImportanceOpen ? criteriaData.fields.map(({
                            id,
                            name,
                            hide_importance,
                            hide_field,
                        }, idx) => (
                            <div key={id} className={`app-criteria-field ${hide_importance === '1' ? 'hidden ' : ''}`}>
                                <label>{getFieldName(name)}
                                    <TriangleSlider
                                        name={id}
                                        min={0}
                                        max={100}
                                        step={1}
                                        value={+criteriaData.fields[idx].importance}
                                        onChange={(_, value) => onSliderChange(value as number, idx)}
                                        isEditable
                                        isEditingDisabled={hide_field === '1'}
                                        onEditIconClick={() => {
                                            setOpen(true);
                                            setModalContent(
                                                <CriteriaSetting
                                                    field={criteriaData.fields[idx]}
                                                    index={idx}
                                                    fieldInitialValues={fieldInitialValues}
                                                    onTradeoffValueChange={onTradeoffValueChange}
                                                    onSliderChange={onSliderChange}
                                                    handleModalClose={handleClose}
                                                    areCTAsDisplayed
                                                />
                                            )
                                        }}
                                    />
                                </label>
                            </div>
                        )) : (
                            <CriteriaSettings
                                criteriaData={criteriaData}
                                onSliderChange={onSliderChange}
                                onTradeoffValueChange={onTradeoffValueChange}
                                fieldInitialValues={fieldInitialValues}
                                handleClose={handleClose}
                            />
                        )}
                    </>,
                ]}
            />
            <div className='app-criteria-actions' style={{display: "block"}}>
                <div style={{marginBottom: 20}}>
                    <Button
                        className='app-criteria-actions-action'
                        type='submit'
                        variant='outlined'
                        style={{marginRight: '4%', width: '48%'}}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsImportanceOpen(isOpen => !isOpen);
                            // the state update is a step behind
                            if (!isImportanceOpen) {
                                scrollToAnchor('app-criteria');
                            }
                        }}
                    >
                        {isImportanceOpen ? 'Settings' : 'Importance'}
                    </Button>
                    <Button
                        className='app-criteria-actions-action'
                        variant='outlined'
                        type='button'
                        onClick={onResetTradeOffs}
                        style={{width: '48%'}}
                    >
                        Reset
                    </Button>
                </div>
                <div>
                    <Button
                        className='app-criteria-actions-action'
                        type='submit'
                        variant='contained'
                        style={{width: '100%'}}
                        onClick={onCriteriaApply}
                    >
                        Decide
                    </Button>
                </div>
            </div>
            <Modal isOpen={open} handleClose={handleClose}>
                {modalContent}
            </Modal>
        </div>
    );
}

export default CriteriaImportanceAndSettings;