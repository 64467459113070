import React, { useState, useEffect } from "react";
import Leaf from "./components/Leaf";
import {AppCriteriaField} from "../../types/decisions";
import {getFieldName} from "../../utils";

interface BubbleUIProps {
    initialCriteriaFields: AppCriteriaField[];
    onCriteriaApply: () => void;
    onTradeoffValueChange: (newValue: number, idx: number) => void,
    onSliderChange: (newValue: number, idx: number) => void,
    onCriteriaSettingsOpen: (index: number) => void,
    style?: React.CSSProperties;
}

const BubbleUI: React.FC<BubbleUIProps> = ({
    initialCriteriaFields,
    onCriteriaApply,
    onSliderChange,
    onCriteriaSettingsOpen,
    style,
}) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [leafStates, setLeafStates] = useState<{ id: string; name: string; angle: number; radius: number; }[]>([]);

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setLeafStates(
            initialCriteriaFields.map(({ name, importance, id }) => ({
                id,
                name: getFieldName(name),
                angle: 0,
                radius: 80 + (120 * (importance as number)) / 100,
            }))
        );
    }, [initialCriteriaFields]);

    useEffect(() => {
        if (containerRef.current) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            setDimensions({ width: offsetWidth, height: offsetHeight });
            const angleStep = (2 * Math.PI) / initialCriteriaFields.length;
            setLeafStates((prev) =>
                prev.map((leaf, index) => ({
                    ...leaf,
                    angle: index * angleStep,
                }))
            );
        }
    }, [initialCriteriaFields]);

    const handleDrag = (index: number, newRadius: number) => {
        setLeafStates((prev) =>
            prev.map((leaf, i) => (i === index ? { ...leaf, radius: newRadius } : leaf))
        );
    };

    const center = {
        x: dimensions.width / 2,
        y: dimensions.height / 2,
    };

    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                ...style,
            }}
        >
            <div
                style={{
                    position: "absolute",
                    left: center.x,
                    top: center.y,
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#F19E38FF",
                    color: "#fff",
                    borderRadius: "50%",
                    width: 80,
                    height: 80,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    zIndex: 2,
                    cursor: "pointer",
                }}
                onClick={onCriteriaApply}
            >
                Decide
            </div>

            {leafStates.map((leaf, index) => (
                <Leaf
                    key={index}
                    name={leaf.name}
                    angle={leaf.angle}
                    radius={leaf.radius}
                    center={center}
                    onDrag={(newRadius) => {
                        const newImportance = Math.round(((newRadius - 80) / (120)) * 100);
                        onSliderChange(newImportance, index);
                        handleDrag(index, newRadius);
                    }}
                    onOpenSettings={() => onCriteriaSettingsOpen(index)}
                />
            ))}
        </div>
    );
};

export default BubbleUI;
