import React, {useEffect, useState} from "react";
import {Button, Checkbox, FormControl, FormControlLabel, IconButton, MenuItem, Select, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {DesktopDatePicker} from "@mui/x-date-pickers";
import Slider from "../../slider/Slider";
import {TriangleSlider} from "../../slider";
import Map from "../../map";
import {CriteriaConstraint} from "../../../types/decisions";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import ColorPicker from "./ColorPicker";

export const transformCriteriaObjectToArray = (data: {}): { name: string, value: number } [] => {
    const result: { name: string, value: number }[] = [];
    const keys = Object.keys(data);
    keys.forEach((key) => {
        // @ts-ignore
        result.push({name: key, value: data[key]});
    })
    return result;
}

const DEFAULT_NEW_COLOR = '#000000';
const MAX_COLOR_COUNT = 5;

export const AppCriteriaTypeText = ({
    importance,
    value,
    hideValues,
    hideImportance,
    onSliderChange,
    onTradeoffValueChange,
    idx,
    showAddConstraint,
    setCriteriaConstraints,
    criteriaConstraints,
    id,
}: {
    importance: number;
    value: string;
    showAddConstraint?: boolean;
    hideImportance?: boolean;
    hideField?: boolean;
    hideValues?: boolean;
    onSliderChange: (value: any, idx: number) => void;
    onTradeoffValueChange: (value: any, idx: number) => void;
    id: string;
    idx: number;
    criteriaConstraints: { [key: string]: CriteriaConstraint[] };
    setCriteriaConstraints: (value: (((prevState: { [p: string]: CriteriaConstraint[] }) => {
        [p: string]: CriteriaConstraint[]
    }) | { [p: string]: CriteriaConstraint[] })) => void;
}) => {
    useEffect(() => {
        if (!criteriaConstraints[id]) {
            setCriteriaConstraints(criteriaConstraints => ({...criteriaConstraints, [id]: [],}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConstraintChange = (value: string, index: number, key: 'value' | 'rule') => {
        setCriteriaConstraints((constraints) => {
            const updatedConstraints = constraints[id].map((constraint, idx) => {
                if (idx !== index) {
                    return constraint;
                }

                return {
                    ...constraint,
                    [key]: value,
                }
            });

            return {
                ...constraints,
                [id]: updatedConstraints,
            };
        });
    };

    const onConstraintOperatorChange = (value: 'and' | 'or', index: number) => {
        setCriteriaConstraints((constraints) => {
            const updatedConstraints = constraints[id].map((constraint, idx) => {
                if (idx !== index) {
                    return constraint;
                }

                return {
                    ...constraint,
                    operator: value,
                }
            });

            return {
                ...constraints,
                [id]: updatedConstraints,
            };
        });
    };

    const onAddConstraint = () => {
        setCriteriaConstraints(constraints => ({
            ...constraints,
            [id]: [...constraints[id], {
                value: '',
                rule: 'em',
                operator: constraints[id].length > 0 ? 'and' : null
            }]
        }));
    };

    const onDeleteConstraint = (index: number) => {
        setCriteriaConstraints(constraints => {
            const filtered = constraints[id].filter((_, idx) => idx !== index);
            if (!filtered.length) {
                return {
                    ...constraints,
                    [id]: [],
                };
            }

            filtered[0] = {
                ...filtered[0],
                operator: null
            };

            return {
                ...constraints,
                [id]: filtered
            };
        })
    };

    return (
        <div>
            {hideValues ? null : (
                <div style={{marginBottom: 10}}>
                    <TextField
                        defaultValue={value}
                        fullWidth
                        size="small"
                        onBlur={(e) => onTradeoffValueChange(e.target.value, idx)}
                    />
                </div>
            )}
            {hideImportance ? null : (
                <div>
                    <TriangleSlider
                        min={0}
                        max={100}
                        step={1}
                        value={importance}
                        leftText='Not Important'
                        rightText='Really Important'
                        onChange={(_, value) => onSliderChange(value, idx)}
                    />
                </div>
            )}
            {!showAddConstraint ? null : (
                <div style={{marginTop: 20}}>
                    <div style={{display: criteriaConstraints[id].length > 0 ? 'block' : 'none'}}>
                        <FormControl sx={{my: 2, minWidth: 120}} size="small">
                            {criteriaConstraints[id].map((constraint, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        marginBottom: 16
                                    }}
                                    key={id + '_' + index}
                                >
                                    {constraint.operator && (
                                        <Select
                                            style={{marginRight: 16}}
                                            variant='outlined'
                                            value={constraint.operator}
                                            onChange={(e) => onConstraintOperatorChange(e.target.value as ('and' | 'or'), index)}
                                        >
                                            <MenuItem value='and'>And</MenuItem>
                                            <MenuItem value='or'>Or</MenuItem>
                                        </Select>
                                    )}
                                    <Select
                                        variant='outlined'
                                        value={constraint.rule}
                                        onChange={(e) => onConstraintChange(e.target.value, index, 'rule')}
                                    >
                                        <MenuItem value='em'>Include if exact match</MenuItem>
                                        <MenuItem value='!em'>Exclude if exact match</MenuItem>
                                        <MenuItem value='instr'>Include if contains</MenuItem>
                                        <MenuItem value='!instr'>exclude if contains</MenuItem>
                                    </Select>
                                    <TextField
                                        style={{marginLeft: 16}}
                                        value={constraint.value}
                                        onBlur={(e) => onConstraintChange(e.target.value, index, 'value')}
                                        size='small'
                                    />
                                    <IconButton style={{marginLeft: '.5rem'}}
                                                onClick={() => onDeleteConstraint(index)}>
                                        <CloseIcon/>
                                    </IconButton>
                                </div>
                            ))}
                        </FormControl>
                    </div>
                    <Button
                        variant='outlined'
                        startIcon={<AddIcon/>}
                        size='small'
                        onClick={onAddConstraint}
                    >
                        Add Constraint
                    </Button>
                </div>
            )}
        </div>
    );
};

export const AppCriteriaTypeNumber = ({
    min,
    max,
    importance,
    value,
    hideValues,
    hideImportance,
    onSliderChange,
    onTradeoffValueChange,
    idx,
    showAddConstraint,
    criteriaConstraints,
    setCriteriaConstraints,
    id,
}: {
    importance: number;
    value: number;
    min: number;
    max: number;
    showAddConstraint?: boolean;
    hideValues?: boolean;
    hideImportance?: boolean;
    onSliderChange: (value: any, idx: number) => void;
    onTradeoffValueChange: (value: any, idx: number) => void;
    idx: number;
    id: string;
    criteriaConstraints: { [key: string]: CriteriaConstraint[] };
    setCriteriaConstraints: (value: (((prevState: { [p: string]: CriteriaConstraint[] }) => {
        [p: string]: CriteriaConstraint[]
    }) | { [p: string]: CriteriaConstraint[] })) => void;
}) => {
    useEffect(() => {
        if (!criteriaConstraints[id]) {
            setCriteriaConstraints(criteriaConstraints => ({
                ...criteriaConstraints,
                [id]: [],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConstraintChange = (value: string, index: number, key: 'value' | 'rule') => {
        setCriteriaConstraints((constraints) => {
            const updatedConstraints = constraints[id].map((constraint, idx) => {
                if (idx !== index) {
                    return constraint;
                }

                return {
                    ...constraint,
                    [key]: value,
                }
            });

            return {
                ...constraints,
                [id]: updatedConstraints,
            };
        });
    };

    const onConstraintOperatorChange = (value: 'and' | 'or', index: number) => {
        setCriteriaConstraints((constraints) => {
            const updatedConstraints = constraints[id].map((constraint, idx) => {
                if (idx !== index) {
                    return constraint;
                }

                return {
                    ...constraint,
                    operator: value,
                }
            });

            return {
                ...constraints,
                [id]: updatedConstraints,
            };
        });
    };

    const onAddConstraint = () => {
        setCriteriaConstraints(constraints => ({
            ...constraints,
            [id]: [...constraints[id], {
                value: '',
                rule: '=',
                operator: constraints[id].length > 0 ? 'and' : null
            }]
        }));
    };

    const onDeleteConstraint = (index: number) => {
        setCriteriaConstraints(constraints => {
            const filtered = constraints[id].filter((_, idx) => idx !== index);
            if (!filtered.length) {
                return {
                    ...constraints,
                    [id]: [],
                };
            }

            filtered[0] = {
                ...filtered[0],
                operator: null
            };

            return {
                ...constraints,
                [id]: filtered
            };
        })
    };

    return (
        <div>
            {hideValues ? null : (
                <div style={{marginBottom: 10}}>
                    <Slider
                        min={min}
                        max={max}
                        defaultValue={value}
                        hideThumbButtons
                        leftText={min + ''}
                        rightText={max + ''}
                        onChangeCommitted={(_, value) => onTradeoffValueChange(value, idx)}
                        valueLabelDisplay={hideImportance ? 'auto' : 'on'}
                    />
                </div>
            )}
            {hideImportance ? null : (
                <div>
                    <TriangleSlider
                        min={0}
                        max={100}
                        step={1}
                        value={importance}
                        leftText='Not Important'
                        rightText='Really Important'
                        onChange={(_, value) => onSliderChange(value, idx)}
                    />
                </div>
            )}
            {!showAddConstraint ? null : (
                <div style={{marginTop: 20}}>
                    <div style={{display: criteriaConstraints[id].length > 0 ? 'block' : 'none'}}>
                        <FormControl sx={{my: 2, minWidth: 120}} size="small">
                            {criteriaConstraints[id].map((constraint, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        marginBottom: 16
                                    }}
                                    key={id + '_' + index}
                                >
                                    {constraint.operator && (
                                        <Select
                                            style={{marginRight: 16}}
                                            variant='outlined'
                                            value={constraint.operator}
                                            onChange={(e) => onConstraintOperatorChange(e.target.value as ('and' | 'or'), index)}
                                        >
                                            <MenuItem value='and'>And</MenuItem>
                                            <MenuItem value='or'>Or</MenuItem>
                                        </Select>
                                    )}
                                    <Select
                                        variant='outlined'
                                        value={constraint.rule}
                                        onChange={(e) => onConstraintChange(e.target.value, index, 'rule')}
                                    >
                                        <MenuItem value='='>Is equal to</MenuItem>
                                        <MenuItem value='!='>Is not equal to</MenuItem>
                                        <MenuItem value='>'>Is higher than</MenuItem>
                                        <MenuItem value='<'>Is less than</MenuItem>
                                    </Select>
                                    <TextField
                                        style={{marginLeft: 16}}
                                        value={constraint.value}
                                        onBlur={(e) => onConstraintChange(e.target.value, index, 'value')}
                                        size='small'
                                    />
                                    <IconButton style={{marginLeft: '.5rem'}}
                                                onClick={() => onDeleteConstraint(index)}>
                                        <CloseIcon/>
                                    </IconButton>
                                </div>
                            ))}
                        </FormControl>
                    </div>
                    <Button
                        variant='outlined'
                        startIcon={<AddIcon/>}
                        size='small'
                        onClick={onAddConstraint}
                    >
                        Add Constraint
                    </Button>
                </div>
            )}
        </div>
    );
};

export const AppCriteriaTypeSelect = ({
    importance,
    items,
    hideValues,
    hideImportance,
    onSliderChange,
    onTradeoffValueChange,
    initialValues,
    idx,
    showAddConstraint,
    setCriteriaConstraints,
    criteriaConstraints,
    id,
}: {
    importance: number;
    items: { name: string, value: number | string }[];
    hideValues?: boolean;
    showAddConstraint?: boolean;
    hideImportance?: boolean;
    onSliderChange: (value: any, idx: number) => void;
    onTradeoffValueChange: (value: any, idx: number, key: string) => void;
    initialValues: { [key: string]: string };
    idx: number;
    id: string;
    criteriaConstraints: { [key: string]: CriteriaConstraint[] };
    setCriteriaConstraints: (value: (((prevState: { [p: string]: CriteriaConstraint[] }) => {
        [p: string]: CriteriaConstraint[]
    }) | { [p: string]: CriteriaConstraint[] })) => void;
}) => {
    const [itemValues, setItemValues] = useState<{ [key: string]: { isChecked: boolean, value: string } }>({});
    useEffect(() => {
        if (!criteriaConstraints[id]) {
            setCriteriaConstraints(criteriaConstraints => ({
                ...criteriaConstraints,
                [id]: [],
            }));
        }

        const itemsChecked: { [key: string]: { isChecked: boolean, value: string } } = {};
        items.forEach(item => {
            itemsChecked[item.name] = {
                isChecked: item.value !== '-1',
                value: item.value as string,
            }
        });
        setItemValues(itemsChecked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConstraintChange = (value: string, index: number, key: 'value' | 'rule') => {
        setCriteriaConstraints((constraints) => {
            const updatedConstraints = constraints[id].map((constraint, idx) => {
                if (idx !== index) {
                    return constraint;
                }

                return {
                    ...constraint,
                    [key]: value,
                }
            });

            return {
                ...constraints,
                [id]: updatedConstraints,
            };
        });
    };

    const onConstraintOperatorChange = (value: 'and' | 'or', index: number) => {
        setCriteriaConstraints((constraints) => {
            const updatedConstraints = constraints[id].map((constraint, idx) => {
                if (idx !== index) {
                    return constraint;
                }

                return {
                    ...constraint,
                    operator: value,
                }
            });

            return {
                ...constraints,
                [id]: updatedConstraints,
            };
        });
    };

    const onAddConstraint = () => {
        setCriteriaConstraints(constraints => ({
            ...constraints,
            [id]: [...constraints[id], {
                value: items[0].name as string || '',
                rule: '=',
                operator: constraints[id].length > 0 ? 'and' : null
            }]
        }));
    };

    const onDeleteConstraint = (index: number) => {
        setCriteriaConstraints(constraints => {
            const filtered = constraints[id].filter((_, idx) => idx !== index);
            if (!filtered.length) {
                return {
                    ...constraints,
                    [id]: [],
                };
            }

            filtered[0] = {
                ...filtered[0],
                operator: null
            };

            return {
                ...constraints,
                [id]: filtered
            };
        })
    };

    return (
        <div>
            {hideValues ? null : (
                <div style={{marginBottom: 10}}>
                    <div className='multi-select-settings' style={{display: hideImportance ? 'block' : 'flex'}}>
                        {items.map((item, index) => (
                            <div
                                key={item.name + index} className='multi-select-settings-item'
                                style={{width: hideImportance ? '100%' : '48%'}}
                            >
                                <div className='custom-slider-label truncate'>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked={item.value !== '-1'}/>}
                                        label={item.name}
                                        checked={itemValues[item.name]?.isChecked}
                                        onChange={(_, checked) => {
                                            setItemValues(items => ({
                                                ...items,
                                                [item.name]: {
                                                    value: checked ? '50' : '-1',
                                                    isChecked: checked,
                                                }
                                            }));
                                            if (!checked) {
                                                onTradeoffValueChange('-1', idx, item.name);
                                            } else {
                                                let defaultValue = initialValues[item.name];
                                                if (defaultValue === '-1') {
                                                    defaultValue = '50';
                                                }
                                                onTradeoffValueChange(defaultValue, idx, item.name);
                                            }
                                        }}
                                    />
                                </div>
                                <div className='custom-slider'>
                                    <Slider
                                        disabled={!itemValues[item.name]?.isChecked}
                                        min={0}
                                        max={100}
                                        value={+itemValues[item.name]?.value}
                                        onChange={(_, value) => {
                                            setItemValues(items => ({
                                                ...items,
                                                [item.name]: {
                                                    ...items[item.name],
                                                    value: value + '',
                                                }
                                            }))
                                        }}
                                        onChangeCommitted={(_, value) => onTradeoffValueChange(value, idx, item.name)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {hideImportance ? null : (
                <div>
                    <TriangleSlider
                        min={0}
                        max={100}
                        step={1}
                        value={importance}
                        leftText='Not Important'
                        rightText='Really Important'
                        onChange={(_, value) => onSliderChange(value, idx)}
                        size='medium'
                    />
                </div>
            )}
            {!showAddConstraint ? null : (
                <div style={{marginTop: 20}}>
                    <div style={{display: criteriaConstraints[id].length > 0 ? 'block' : 'none'}}>
                        <FormControl sx={{my: 2, minWidth: 120}} size="small">
                            {criteriaConstraints[id].map((constraint, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        marginBottom: 16
                                    }}
                                    key={id + '_' + index}
                                >
                                    {constraint.operator && (
                                        <Select
                                            style={{marginRight: 16}}
                                            variant='outlined'
                                            value={constraint.operator}
                                            onChange={(e) => onConstraintOperatorChange(e.target.value as ('and' | 'or'), index)}
                                        >
                                            <MenuItem value='and'>And</MenuItem>
                                            <MenuItem value='or'>Or</MenuItem>
                                        </Select>
                                    )}
                                    <Select
                                        variant='outlined'
                                        value={constraint.rule}
                                        onChange={(e) => onConstraintChange(e.target.value, index, 'rule')}
                                    >
                                        <MenuItem value='='>Is</MenuItem>
                                        <MenuItem value='!='>Is not</MenuItem>
                                    </Select>
                                    <Select
                                        style={{marginLeft: 16}}
                                        value={constraint.value}
                                        onChange={(e) => onConstraintChange(e.target.value, index, 'value')}
                                        size='small'
                                        variant='outlined'
                                    >
                                        {items.map(({name}) => (
                                            <MenuItem value={name} key={name}>{name}</MenuItem>
                                        ))}
                                        <MenuItem value='-'>NULL</MenuItem>
                                    </Select>
                                    <IconButton style={{marginLeft: '.5rem'}} onClick={() => onDeleteConstraint(index)}>
                                        <CloseIcon/>
                                    </IconButton>
                                </div>
                            ))}
                        </FormControl>
                    </div>
                    <Button
                        variant='outlined'
                        startIcon={<AddIcon/>}
                        size='small'
                        onClick={onAddConstraint}
                    >
                        Add Constraint
                    </Button>
                </div>
            )}
        </div>
    );
};

export const AppCriteriaTypeZip = ({
    importance,
    hideValues,
    hideImportance,
    onSliderChange,
    onTradeoffValueChange,
    idx,
    showAddConstraint,
}: {
    importance: number;
    hideValues?: boolean;
    hideImportance?: boolean;
    showAddConstraint?: boolean;
    onSliderChange: (value: any, idx: number) => void;
    onTradeoffValueChange: (value: any, idx: number, key: string) => void;
    idx: number;
}) => {
    const onPointSelect = (items: { lat: number, lng: number }[]) => {
        onTradeoffValueChange(items.map(item => item.lat + ',' + item.lng), idx, '')
    };

    return (
        <div>
            {hideValues ? null : (
                <div style={{marginBottom: 10}}>
                    <Map onPointSelect={onPointSelect}/>
                </div>
            )}
            {hideImportance ? null : (
                <div>
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={importance}
                        leftText='Not Important'
                        rightText='Really Important'
                        onChangeCommitted={(_, value) => onSliderChange(value, idx)}
                    />
                </div>
            )}
            {!showAddConstraint ? null : (
                <div style={{marginTop: 20}}>
                    <Button
                        variant='outlined'
                        startIcon={<AddIcon/>}
                        size='small'
                    >
                        Add Constraint
                    </Button>
                </div>
            )}
        </div>
    );
};

export const AppCriteriaTypeDate = ({
    importance,
    hideValues,
    hideImportance,
    onSliderChange,
    onTradeoffValueChange,
    idx,
    showAddConstraint,
    value,
}: {
    importance: number;
    hideValues?: boolean;
    hideImportance?: boolean;
    showAddConstraint?: boolean;
    onSliderChange: (value: any, idx: number) => void;
    onTradeoffValueChange: (value: any, idx: number, key: string) => void;
    idx: number;
    value: string;
}) => {
    const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(value));

    const onDateChange = (value: Dayjs | null) => {
        setDateValue(value);
        const dateString = value?.format('MM/DD/YYYY');
        onTradeoffValueChange(dateString, idx, '');
    };

    return (
        <div>
            {hideValues ? null : (
                <div style={{marginBottom: 10}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['MuiDateField']}>
                            <DesktopDatePicker
                                label='Date'
                                value={dateValue}
                                onChange={onDateChange}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
            )}
            {hideImportance ? null : (
                <div>
                    <TriangleSlider
                        min={0}
                        max={100}
                        step={1}
                        value={importance}
                        leftText='Not Important'
                        rightText='Really Important'
                        onChange={(_, value) => onSliderChange(value, idx)}
                    />
                </div>
            )}
            {!showAddConstraint ? null : (
                <div style={{marginTop: 20}}>
                    <Button
                        variant='outlined'
                        startIcon={<AddIcon/>}
                        size='small'
                    >
                        Add Constraint
                    </Button>
                </div>
            )}
        </div>
    );
};

export const AppCriteriaTypeColor = ({
    importance,
    hideValues,
    hideImportance,
    onSliderChange,
    onTradeoffValueChange,
    idx,
    showAddConstraint,
    value,
}: {
    importance: number;
    hideValues?: boolean;
    hideImportance?: boolean;
    showAddConstraint?: boolean;
    onSliderChange: (value: any, idx: number) => void;
    onTradeoffValueChange: (value: any, idx: number, key: string) => void;
    idx: number;
    value: string;
}) => {
    const [colorValues, setColorValues] = useState<string[]>(value as unknown as string[]);

    const onColorChange = (newColor: string, index: number) => {
        setColorValues(colors => {
            const newColors = [
                ...colors.slice(0, index),
                newColor,
                ...colors.slice(index + 1),
            ];
            onTradeoffValueChange(newColors, idx, '');

            return newColors;
        });
    };

    const onColorRemove = (index: number) => {
        setColorValues(colors => {
            const newColors = colors.filter((_, idx) => index !== idx);
            onTradeoffValueChange(newColors, idx, '')

            return newColors;
        });
    };

    const onColorAdd = () => {
        setColorValues(colors => {
            const newColors = [...colors, DEFAULT_NEW_COLOR];
            onTradeoffValueChange(newColors, idx, '')

            return newColors;
        });
    };

    const onColorChangeCommited = () => {
        // TODO optimize
        colorValues.forEach((color, index) => {
            onTradeoffValueChange(color, index, '');
        });
    }

    return (
        <div>
            {hideValues ? null : (
                <div style={{marginBottom: 10}}>
                    {colorValues.map((value, index) => (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                            <ColorPicker
                                key={index}
                                value={value}
                                onChange={e => onColorChange(e.target.value, index)}
                                onChangeCommited={onColorChangeCommited}
                            />
                            <Button onClick={() => onColorRemove(index)}>
                                <CloseIcon/>
                            </Button>
                        </div>
                    ))}
                    <Button
                        startIcon={<AddIcon/>}
                        onClick={onColorAdd}
                        component='button'
                        disabled={colorValues.length >= MAX_COLOR_COUNT}
                    >Add Color</Button>
                </div>
            )}
            {hideImportance ? null : (
                <div>
                    <TriangleSlider
                        min={0}
                        max={100}
                        step={1}
                        value={importance}
                        leftText='Not Important'
                        rightText='Really Important'
                        onChange={(_, value) => onSliderChange(value, idx)}
                    />
                </div>
            )}
            {!showAddConstraint ? null : (
                <div style={{marginTop: 20}}>
                    <Button
                        variant='outlined'
                        startIcon={<AddIcon/>}
                        size='small'
                    >
                        Add Constraint
                    </Button>
                </div>
            )}
        </div>
    );
};